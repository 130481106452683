html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
hd,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
hd,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  font-size: 10px;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: '';
  content: none;
}
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  color: #333;
  font-family: 'Hiragino Kaku Gothic ProN,YuGothic,Yu Gothic Medium,Yu Gothic,Meiryo,Verdana,Tahoma,Arial', sans-serif;
}
a {
  color: #333;
  cursor: pointer;
}
.outer {
  padding: 40px 0 80px;
}
@media only screen and (max-width: 1000px) {
  .outer {
    padding: 20px 0 20px;
    overflow: hidden;
  }
}
.site-title {
  width: 1000px;
  margin: 0 auto 80px;
}
@media only screen and (max-width: 1000px) {
  .site-title {
    width: 100%;
    margin-bottom: 20px;
  }
}
.site-copy {
  font-size: 16px;
  margin: 30px auto 0;
  padding: 0 15px;
  text-align: center;
  max-width: 1000px;
}
.site-logo {
  position: relative;
  width: 200px;
  margin: 0 auto;
  transition: all 0.3s ease;
}
.site-logo img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
.section {
  padding: 70px 0;
}
@media only screen and (max-width: 1000px) {
  .section {
    padding: 40px 10px;
  }
}
.section:nth-child(2n-1) {
  background: linear-gradient(45deg, #fff, #f2f8f8);
}
.section-inner {
  margin: 0 auto;
  max-width: 1000px;
}
.section-head {
  margin-bottom: 30px;
  line-height: 1.3;
  font-size: 28px;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
}
.section-head[data-lang="ja"] {
  font-family: 'Hiragino Kaku Gothic ProN,YuGothic,Yu Gothic Medium,Yu Gothic,Meiryo,Verdana,Tahoma,Arial', sans-serif;
}
.section-body p {
  font-size: 15px;
  line-height: 1.8;
}
.section-body p:not(:first-child) {
  margin-top: 15px;
}
.section-body figure:not(:first-child) {
  margin-top: 15px;
}
.section-body img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
}
.section-body dl {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .section-body dl {
    flex-direction: column;
  }
}
.section-body dl + dl {
  margin-top: 20px;
}
.section-body dt {
  font-size: 16px;
  font-weight: bold;
  width: 140px;
  padding-right: 20px;
  text-align: right;
}
@media only screen and (max-width: 1000px) {
  .section-body dt {
    width: auto;
    padding: 0;
    margin-bottom: 6px;
    text-align: left;
  }
}
.section-body dd {
  font-size: 16px;
  width: calc(100% - 200px);
}
@media only screen and (max-width: 1000px) {
  .section-body dd {
    width: auto;
  }
}
.section-body a {
  transition: all 0.2s ease;
  color: #e85599;
  text-decoration: none;
}
.section-body a:hover {
  opacity: 0.7;
}
.profile-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1000px) {
  .profile-wrapper {
    flex-direction: column;
  }
}
.profile-image {
  width: 200px;
}
@media only screen and (max-width: 1000px) {
  .profile-image {
    margin: 0 auto 20px;
  }
}
.profile-image img {
  width: 100%;
  height: auto;
  vertical-align: top;
  border-radius: 50%;
}
.profile-image figcaption {
  margin-top: 10px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
}
.profile-text {
  width: calc(100% - 200px - 30px);
}
@media only screen and (max-width: 1000px) {
  .profile-text {
    width: auto;
  }
}
.profile-paragraph:not(:first-child) {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #333;
}
@media only screen and (max-width: 1000px) {
  .profile-paragraph:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
  }
}
.img-row {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1000px) {
  .img-row {
    flex-direction: column;
  }
}
.img-row p {
  width: calc(100% - 212px);
}
@media only screen and (max-width: 1000px) {
  .img-row p {
    width: 100%;
  }
}
.img-row .img {
  width: 212px;
  height: 170px;
  margin-left: 20px;
  background: #a5a5a5;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1000px) {
  .img-row .img {
    margin-left: 0;
    margin-top: 10px;
  }
}
.img-row img {
  width: 100%;
  vertical-align: top;
}
.img-row:not(:first-child) {
  margin-top: 15px;
}
.img-row .frame {
  position: relative;
  z-index: 32;
}
.img-row .frame:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("/asset/photoframe.png") no-repeat;
  background-size: cover;
  content: "";
  z-index: 35;
  transform: scale(1.08);
}
.banner-list {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .banner-list {
    flex-direction: column;
    align-items: center;
  }
}
.banner {
  display: block;
  width: 300px;
  background: #eee;
  border: 1px solid #ddd;
  transition: all 0.2s ease;
}
.banner img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
.banner + .banner {
  margin-left: 20px;
}
@media only screen and (max-width: 1000px) {
  .banner + .banner {
    margin-top: 10px;
    margin-left: 0;
  }
}
.banner:hover {
  opacity: 0.7;
}
.mail {
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
}
.images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1000px;
  margin: 30px auto 50px;
  position: relative;
}
@media only screen and (max-width: 1000px) {
  .images {
    width: auto;
    margin: 20px 10px 20px;
  }
}
.images-cell {
  width: 32%;
  background: #ddd;
  transform: translateY(-20px);
  margin-top: 20px;
}
.images-cell img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
@media only screen and (max-width: 1000px) {
  .images-cell {
    margin-left: auto;
    margin-right: auto;
  }
}
.hd-inner {
  width: 1000px;
  margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
  .hd-inner {
    width: 100%;
  }
}
.hd-logo {
  position: relative;
  width: 82.33333333333333px;
  height: 15.333333333333334px;
  text-indent: -8192px;
  cursor: pointer;
}
.nav .hd-logo {
  margin: 8px auto 0;
}
.hd-logo:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: url("/asset/logo.png");
  background-size: cover;
}
.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.nav:not(:first-child) {
  margin-top: 15px;
}
@media only screen and (max-width: 1000px) {
  .nav {
    padding: 0 10px;
  }
}
.nav-mobile-button {
  position: absolute;
  display: none;
  width: 45px;
  height: 70px;
  top: 0;
  right: 5px;
}
@media only screen and (max-width: 1000px) {
  .nav-mobile-button {
    display: block;
  }
}
.nav-mobile-button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10px);
  content: "";
  width: 28px;
  height: 5px;
  border-top: 2px double #fff;
  border-bottom: 2px double #fff;
  transition: all 0.2s ease;
}
.nav-mobile-button:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 2px);
  content: "";
  width: 28px;
  height: 5px;
  border-top: 2px double #fff;
  border-bottom: 2px double #fff;
  transition: all 0.2s ease;
}
.open .nav-mobile-button:before,
.open .nav-mobile-button:after {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 34px;
  height: 2px;
  content: "";
  background-color: #fff;
  border-width: 0;
  border-color: transparent;
}
.open .nav-mobile-button:before {
  transform: rotate(45deg);
}
.open .nav-mobile-button:after {
  transform: rotate(-45deg);
}
.nav-group {
  position: relative;
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .nav-group {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .nav-group.for-mobile {
    display: flex;
  }
}
.nav-group+.nav-group {
  padding-left: 50px;
}
@media only screen and (max-width: 1000px) {
  .nav-group+.nav-group {
    padding-left: 0;
  }
}
.nav-group+.nav-group:before {
  position: absolute;
  left: 25px;
  top: 50%;
  content: "";
  transform: translateY(-50%);
  width: 1px;
  height: 15px;
  background-color: #fff;
}
@media only screen and (max-width: 1000px) {
  .nav-group+.nav-group:before {
    display: none;
  }
}
.nav-item {
  display: block;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
  padding: 0 30px;
  transition: all 0.2s ease;
  text-decoration: none;
}
@media only screen and (max-width: 1000px) {
  .nav-item {
    padding: 0;
    width: 50%;
    text-align: center;
    color: #333;
  }
}
.nav-item.anchor {
  position: relative;
}
.nav-item.anchor:before {
  position: absolute;
  opacity: 0;
  transition: all 0.2s ease;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -5px) rotate(-135deg);
  content: "";
  border-style: solid;
  border-width: 5px;
  border-color: #333 transparent transparent #333;
}
.nav-item.anchor:hover:before {
  opacity: 1;
}
.nav-item.icon {
  padding: 0;
  width: 36px;
  height: 40px;
  text-indent: -65535px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 100% auto;
  transition: all 0.2s ease;
}
.nav-item.icon:hover {
  opacity: 0.7;
}
.nav-item.icon + .nav-item.icon {
  margin-left: 10px;
}
.nav-item.youtube {
  background-image: url("/asset/icon_youtube.png");
}
.nav-item.twitter {
  background-image: url("/asset/icon_twitter.png");
}
.nav-item.facebook {
  background-image: url("/asset/icon_facebook.png");
}
.nav-item.youtube {
  background-image: url("/asset/icon_youtube.png");
}
.nav-item-service {
  line-height: 1.2;
  text-align: center;
}
.nav-item-sub-text {
  font-size: 12px;
  text-decoration: none;
  color: #cbe4e2;
  margin-top: 2px;
}
@media only screen and (max-width: 1000px) {
  .top-level .nav-item {
    color: #fff;
  }
}
.top-level .nav-item.current {
  height: 70px;
  border-bottom: 5px solid #fff;
}
.top-level .nav-item:not(.anchor):hover,
.top-level .nav-item.current {
  color: #fff;
  background-color: #00a99d;
}
.movie {
  margin-top: 50px;
}
@media only screen and (max-width: 1000px) {
  .movie iframe {
    width: 100%;
  }
}
.text {
  font-size: 16px;
  line-height: 1.8;
}
.text + .text {
  margin-top: 20px;
}
.text-hd {
  font-size: 16px;
  font-weight: bold;
}
.paragraph + .paragraph {
  margin-top: 20px;
}
.service-item {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .service-item {
    flex-direction: column;
  }
}
.service-item:not(:first-child) {
  margin-top: 60px;
}
.service-image {
  width: 400px;
  min-height: 100px;
}
@media only screen and (max-width: 1000px) {
  .service-image {
    width: 100%;
    max-width: 500px;
  }
}
.service-image a {
  display: block;
  text-align: center;
  transition: all 0.2s ease;
}
.service-image a:hover {
  opacity: 0.7;
}
.service-text {
  width: calc(100% - 400px);
}
@media only screen and (max-width: 1000px) {
  .service-text {
    width: auto;
  }
}
.service-text:not(:first-child) {
  margin-left: 30px;
}
@media only screen and (max-width: 1000px) {
  .service-text:not(:first-child) {
    margin-left: 0;
    margin-top: 20px;
  }
}
.service-text p {
  font-size: 16px;
  line-height: 1.8;
}
.service-title {
  font-size: 28px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1000px) {
  .service-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
}
.button {
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #00a99d;
  font-size: 16px;
  border-radius: 5px;
  padding: 12px 20px;
  min-width: 300px;
  text-align: center;
  line-height: 1.1;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;
}
.button:hover {
  opacity: 0.7;
}
.button.sub {
  background-color: #e5e9eb;
  color: #828385;
  border: none;
}
.button[target="_blank"]:before,
.button[target="_blank"]:after {
  position: absolute;
  top: 17px;
  right: 15px;
  content: "";
  width: 12px;
  height: 12px;
  border: 1px solid #00a99d;
  background: #fff;
  border-radius: 2px;
}
.button[target="_blank"]:after {
  transform: translate(-5px, -5px);
}
.footer-inner {
  width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
}
@media only screen and (max-width: 1000px) {
  .footer-inner {
    width: 100%;
  }
}
.copyright {
  font-size: 15px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 50px;
}
.mail:not(.open) {
  cursor: pointer;
}
.commercial dt {
  color: #888;
}
